class ImageHotspot extends HTMLElement {
    constructor() {
      super();
      this.setAttribute('role', 'button');
      this.setAttribute('tabindex', '0');
      this._toggle = this.querySelector('.image-hotspot-icon');
      this._content = this.querySelector('image-hotspot-content');
    }
  
    connectedCallback() {
      this.addEventListener("click", this.onClick.bind(this));
      this._toggle.setAttribute('role', 'button');
      this.setAttribute('aria-expanded', 'false');
      this._content.setAttribute('aria-hidden', 'true');
    }

    //DEV NOTE -- leaving this here in case we can use this to get variant URLS
    /*
    async fetchVariantData() {
        console.log(window.Shopify.routes.root + "products/" + this.dataset.productHandle + ".js")
        this._productData = fetch(window.Shopify.routes.root + "products/" + this.dataset.productHandle + ".js")
            .then(res => res.json())
            .then(data => { return data })
            const data = await this._productData;
            console.log(data.variants)
            this._variantData = data.variants.find(variant => {
                variant.sku.includes(this.dataset.variantHandle)
            });
            console.log(this._variantData)

    }
    */

    onBodyClick(event) {
        if(!event.target.closest('image-hotspot')) this.toggle();
    }
  
    onClick(event) {
        const expanded = this.getAttribute('aria-expanded') === 'true';
        const imageHotspotGroup = this.closest('image-hotspot-group');
        this._overlay = imageHotspotGroup.querySelector('image-overlay');
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        document.body.addEventListener('click', this.onBodyClickEvent);
        if(imageHotspotGroup) {
            imageHotspotGroup.closeAllExcept(this)
        }

        if(expanded) {
            this._content.classList.remove('opacity-1', 'pointer-events-auto', 'flex');
            this._content.classList.add('opacity-0', 'pointer-events-none', 'hidden');
            this._overlay.classList.add('opacity-0');
            this._overlay.classList.remove('opacity-50');
            this.classList.add('z-20');
            this.classList.remove('z-30');
            this.querySelector('.ping').classList.add('animate-ping');
            document.body.removeEventListener('click', this.onBodyClickEvent);
        } else {
            this._content.classList.remove('opacity-0', 'pointer-events-none', 'hidden');
            this._content.classList.add('opacity-1', 'pointer-events-auto', 'flex');
            this._overlay.classList.remove('opacity-0');
            this._overlay.classList.add('opacity-50');
            this.classList.add('z-30');
            this.classList.remove('z-20');
            this.querySelector('.ping').classList.remove('animate-ping');
        }

      this.setAttribute('aria-expanded', !expanded);
      this._content.setAttribute('aria-hidden', expanded);
      
    }

    toggle() {
        if(this.getAttribute('aria-expanded') === 'true') {
            this.onClick();
        }
    }
  }

  class ImageHotspotContent extends HTMLElement {
    constructor() {
        super();
        this.setAttribute('role', 'tabpanel');
    }

  }

  class ImageHotspotGroup extends HTMLElement {
    constructor() {
        super();
    }
    

    closeAllExcept(exceptHotspot) {
        const hotspots = Array.from(this.querySelectorAll('image-hotspot'));
        hotspots.forEach((hotspot) => {
            if (hotspot !== exceptHotspot) {
                hotspot.toggle();
            }
        });
    }
  }

  class Overlay extends HTMLElement {
    constructor() {
        super();
    }
    }
  
  customElements.define("image-hotspot", ImageHotspot);
  customElements.define("image-overlay", Overlay);
  customElements.define("image-hotspot-content", ImageHotspotContent);
  customElements.define("image-hotspot-group", ImageHotspotGroup);
  